import React, { FormEvent } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import * as ladda from 'ladda'

type Props = {
  forgotPassword: string
  show: boolean
  onCancel: () => void
  onRequestFinished: (response: AxiosResponse) => void
  onRequestFailed: (e: AxiosError) => void
}

export const ForgotPasswordForm = (props: Props) => {
  if (!props.show) {
    return null
  }

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    const submitButton = ev.currentTarget.querySelector<HTMLButtonElement>('button[type=submit]')
    if (submitButton) {
      ladda.create(submitButton).start()
    }

    axios
      .post(props.forgotPassword, {
        email: ev.currentTarget.querySelector<HTMLInputElement>('input[name=email]')?.value
      })
      .then(props.onRequestFinished)
      .catch(props.onRequestFailed)
      .finally(() => ladda.stopAll())
  }

  return (
    <div className="kt-login__forgot">
      <div className="kt-login__head">
        <h3 className="kt-login__title">Forgotten Password ?</h3>
        <div className="kt-login__desc">Enter your email to reset your password:</div>
      </div>
      <div className="kt-login__form">
        <form className="kt-form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              placeholder="Email"
              name="email"
              autoComplete="off"
            />
          </div>
          <div className="kt-login__actions">
            <button type="submit" className="btn btn-brand btn-elevate ladda-button" data-style="zoom-in">
              <span className='ladda-label'>Request</span>
            </button>
            <button type="button" className="btn btn-outline-brand" onClick={props.onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  )
}
