import React from 'react'
import ReactDOM from 'react-dom'
import { Widget, Props } from './Widget'

export default (container: HTMLElement, options: Props) => {
  ReactDOM.render(
    React.createElement(Widget, options),
    container
  )
}
