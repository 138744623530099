import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    sentry: {
      key: string
    } | null
  }
}

if (window.sentry) {
  Sentry.init({
    dsn: window.sentry.key,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
