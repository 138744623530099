import React, { useState } from 'react'
import { SigninForm } from './forms/SigninForm'
import { ForgotPasswordForm } from './forms/ForgotPasswordForm'
import { Alert } from 'react-bootstrap'

export type Props = {
  registrationEnabled?: boolean
  endpoints: {
    login: string
    forgotPassword: string
    register: string
  }
}

export const Widget = (props: Props) => {
  const [form, setForm] = useState<'login' | 'forgot'>('login')
  const [summary, setSummary] = useState<{ type: string, message: string } | null>(null)

  return (
    <div className="kt-login__body">
      <div className="kt-login__logo">
        <a href="#">
          <img src="/images/logo-color.png" style={{ width: '55%' }} alt="logo"/>
        </a>
      </div>
      {summary && <Alert className={`alert-${summary.type}`}>{summary.message}</Alert>}
      <SigninForm
        {...props.endpoints}
        show={form === 'login'}
        onForgotPassword={() => setForm('forgot')}
        showRegisterButton={props.registrationEnabled}
        onFailedLogin={(e) => {
          if (e.response) {
            switch (e.response.status) {
              case 429:
                setSummary({ type: 'solid-danger', message: 'Too many login attempts' })
                break
              default:
                setSummary({
                  type: 'solid-danger',
                  message: e.response.data.message || 'Unknown login error'
                })
            }
          }
        }}
      />
      <ForgotPasswordForm
        {...props.endpoints}
        show={form === 'forgot'}
        onCancel={() => setForm('login')}
        onRequestFinished={(response) => {
          setForm('login')
          if (Object.prototype.hasOwnProperty.call(response.data, 'message')) {
            setSummary({ type: 'solid-success', message: response.data.message })
          }
        }}
        onRequestFailed={(e) => {
          setSummary({
            type: 'solid-danger',
            message: e.response?.data.message || 'Unknown error'
          })
        }}
      />
    </div>
  )
}
