const slugify = require('slugify');
const Ladda = require('ladda');

require('../../common/exceptionHandler');

export default () => {
    let subdomainField = document.querySelector<HTMLInputElement>('#subdomain');
    document.querySelector<HTMLInputElement>('#clientName')?.addEventListener<'input'>('input', (e) => {
        if (subdomainField) {
            subdomainField.placeholder = slugify((e.currentTarget as HTMLInputElement).value, {lower: true, strict: true});
        }
    });

    Ladda.bind('form button[type=submit]');

    (() => {
        const passwordField = document.querySelector<HTMLInputElement>('#password'),
            passwordBar = document.querySelector<HTMLDivElement>('#password-strength .progress-bar'),
            guidelines = document.querySelector<HTMLUListElement>('#password-guidelines'),
            guidelineItems = guidelines?.querySelectorAll<HTMLLIElement>('li');

        const calculateStrength = (password: string): {strength: number, rules: string[]} => {
            let rules = Array<string>();
            if (password.length >= 8) {
                rules.push('length');
            }
            if (password.match(/\W/)) {
                rules.push('symbols')
            }
            if (password.match(/\d/)) {
                rules.push('digits')
            }
            if (password.match(/[A-Z]/) && password.match(/[a-z]/)) {
                rules.push('case');
            }

            return {strength: rules.length, rules};
        }

        passwordField?.addEventListener<'input'>('input', e => {
            const result = calculateStrength((e.currentTarget as HTMLInputElement).value);
            if (passwordBar) {
                passwordBar.style.width = result.strength * 25 + '%';
                switch (true) {
                    case result.strength == 1:
                        passwordBar.className = 'progress-bar bg-danger';
                        passwordBar.innerText = 'Weak';
                        break;
                    case result.strength == 2:
                        passwordBar.className = 'progress-bar bg-warning';
                        passwordBar.innerText = 'Medium';
                        break;
                    case result.strength == 3:
                        passwordBar.className = 'progress-bar bg-info';
                        passwordBar.innerText = 'Strong';
                        break;
                    case result.strength == 4:
                        passwordBar.className = 'progress-bar bg-success';
                        passwordBar.innerText = 'Very Strong';
                }
            }

            if (guidelines) {
                guidelineItems?.forEach(li => {
                    if (result.rules.includes(li.id.match(/pg-([\w]+)/)?.pop() || '')) {
                        li.classList.add('text-success');
                    } else {
                        li.classList.remove('text-success');
                    }

                });
            }
        });
    })();
}
