import React, { FormEvent, useEffect, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ValidationErrors } from '../../../common/interfaces'
import { Form } from 'react-bootstrap'
import Feedback from 'react-bootstrap/Feedback'
import { create as laddaCreate, stopAll as laddaStopAll } from 'ladda'

type Props = {
  readonly login: string
  readonly register: string
  readonly onForgotPassword: () => void
  readonly show: boolean
  readonly showRegisterButton?: boolean
  readonly onFailedLogin: (e: AxiosError) => void
}

export const SigninForm = (props: Props) => {
  if (!props.show) {
    return null
  }
  const [credentials, setCredentials] = useState<{ email: string, password: string, remember: boolean, code: string | null }>({
    email: '',
    password: '',
    remember: false,
    code: null
  })
  const [errors, setErrors] = useState<ValidationErrors>({})
  const [twoFactorRequired, requireTwoFactor] = useState(false)

  let submitButton: HTMLButtonElement | null = null

  const onSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    const submitButton = ev.currentTarget.querySelector<HTMLButtonElement>('button[type=submit]')
    submitButton && laddaCreate(submitButton).start()
    axios
      .post(props.login, credentials)
      .then((response: AxiosResponse) => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'redirectTo')) {
          window.location.href = response.data.redirectTo
        }
      })
      .catch((e: AxiosError) => {
        laddaStopAll()
        if (e.response?.status === 422) {
          if (e.response.data.requireTwoFactor) {
            requireTwoFactor(true)
            return
          }

          setErrors(e.response.data.errors)
          return
        }

        throw e
      }).catch(props.onFailedLogin)
  }

  // Trigger form submit if user entered at least 6 digits in the Two factor code field
  useEffect(() => {
    credentials.code && credentials.code.length >= 6 && submitButton?.click()
  }, [credentials.code])

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setCredentials(prevState => ({ ...prevState, [e.target.name]: value }))
  }

  return (
    <div className="kt-login__signin">
      <div className="kt-login__head">
        <h3 className="kt-login__title">Sign In To Survease</h3>
      </div>
      <div className="kt-login__form">
        <form className="kt-form" onSubmit={onSubmit}>
          {!twoFactorRequired &&
          <div className="form-group login-field">
            <Form.Control
              type="text"
              name="email"
              className="form-control"
              isInvalid={Object.prototype.hasOwnProperty.call(errors, 'email')}
              placeholder="Email"
              value={credentials.email}
              autoComplete="off"
              onChange={inputHandler}
            />
            <Feedback type="invalid">{errors.email && errors.email[0]}</Feedback>
          </div>}
          {!twoFactorRequired && <div className="form-group login-field">
            <Form.Control
              type="password"
              name="password"
              isInvalid={Object.prototype.hasOwnProperty.call(errors, 'password')}
              className={`form-control ${!errors.code ? 'form-control-last' : ''}`}
              placeholder="Password"
              value={credentials.password}
              onChange={inputHandler}
            />
            <Feedback type="invalid">{errors.password && errors.password[0]}</Feedback>
          </div>}
          {twoFactorRequired &&
          <div className="form-group login-field">
            <Form.Control
              type="text"
              name="code"
              isInvalid={Object.prototype.hasOwnProperty.call(errors, 'code')}
              className="form-control form-control-last"
              autoFocus
              placeholder="Two factor code"
              value={credentials.code || ''}
              onChange={inputHandler}
            />
            <Feedback type="invalid">{errors.code && errors.code[0]}</Feedback>
          </div>}
          {!twoFactorRequired && <div className="kt-login__extra">
            <label className="kt-checkbox">
              <input
                type="checkbox"
                name="remember"
                checked={credentials.remember}
                onChange={inputHandler}
                value="1"
              />
              Remember me
              <span/>
            </label>
            <a onClick={props.onForgotPassword} href="#">Forgot Password?</a>
          </div>}
          <div className="kt-login__actions justify-content-center">
            {props.showRegisterButton &&
            <a href={props.register} className="btn btn-success btn-elevate">Start Free Trial</a>}
            <button
              type="submit"
              className="btn btn-brand btn-elevate ladda-button"
              data-style="zoom-in"
              ref={e => (submitButton = e)}
            >
              <span className="ladda-label">Sign In</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
